<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <div class="title mb-3">{{ selectedDoctorInfo.serviceName }}</div>
        <div class="department-name">
          <strong>Клиника:</strong> {{ selectedDoctorInfo.departmentName }}
        </div>
        <div class="doctor-name">
          <strong>Доктор:</strong> {{ selectedDoctorInfo.doctorName }}
        </div>
        <div
          class="price"
          v-if="
            selectedDoctorInfo.servicePrice ||
            selectedDoctorInfo.doctorServicePrice
          "
        >
          <strong>Цена: </strong
          >{{
            selectedDoctorInfo.servicePrice ||
            selectedDoctorInfo.doctorServicePrice
          }}
          руб.
        </div>
        <div class="city-district">
          <strong>Район города: </strong>{{ selectedDoctorInfo.cityPart }}
        </div>
      </v-col>
      <v-col>
        <order-form :services="this.orderData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as names from "store/names";
import OrderForm from "components/partials/OrderForm";

export default {
  name: "MainResultSpecializationFast",
  components: { OrderForm },
  data: () => ({
    orderData: [],
  }),
  mounted() {
    this.collectOrderData();
  },

  computed: {
    ...mapGetters({
      selectedDoctorInfo: names.SELECTED_DOCTOR_INFO,
      entityId: names.ENTITY_ID,
    }),
  },
  methods: {
    ...mapActions({}),
    collectOrderData() {
      this.orderData = {
        departmentName: this.selectedDoctorInfo.departmentName,

        departmentId: this.selectedDoctorInfo.departmentId,

        departmentAddress: this.selectedDoctorInfo.departmentAddress,

        departmentPhone: this.selectedDoctorInfo.departmentPhone,

        serviceName: this.selectedDoctorInfo.serviceName,

        doctorName: this.selectedDoctorInfo.doctorName,

        doctorServicePrice: this.selectedDoctorInfo.doctorServicePrice,

        servicePrice: this.selectedDoctorInfo.servicePrice,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  margin-bottom: 10px;
}
.return-btn {
  color: $deep-blue;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
.v-text-field--outlined {
  .v-input__control {
    .v-input__slot {
      min-height: unset !important;
      height: 38px !important;
    }
  }
}
</style>
